#Contenuto #BustePaga {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 70vw;
    min-width: 70vw;
    padding-top: 50px;
}

#BustePaga button,
#BustePaga form,
#BustePaga select,
#BustePaga hr {
    min-width: 70vw;
    width: 70vw;
    max-width: 70vw;
}

#BustePaga a {
    text-decoration: none;
}

#FileDownload {
    display: inline-grid !important;
}

#Questionario {
    margin-top: 50px !important;
    color: black;
    background-color: orange;
}

#MostraFile {
    height: 1000px;
    max-width: 100%;
    width: 100% !important;
    margin-top: 25px;
    border: 2px solid lightgrey;
    border-radius: 15px;
    display: none;
    justify-content: center;
    margin: 0 auto;
}

#Caricamenti {
    background: ghostwhite;
    border: 1px solid grey;
    padding: 5px;
    border-radius: 5px;
    margin-top: 25px;
}