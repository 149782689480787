#Contenuto #Accesso {
    margin: 0 auto;
    max-width: 52vw !important;
    min-width: 52vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Contenuto #Accesso #Logo,
#Contenuto #Accesso input,
#Contenuto #Accesso button {
    margin-top: 50px;
    margin-bottom: 50px;
    min-width: 20vw;
    max-width: 20vw;
}

#Contenuto #Accesso #Ingresso {
    width: 95%;
    min-width: 95%;
}

button[label=Accetto],
button[label=Rifiuto] {
    width: 50% !important;
    min-width: 50% !important;
    max-width: 50% !important;

    font-weight: bolder !important;
    font-size: x-large;
    text-shadow: 2px 0 black, -2px 0 black, 0 2px black, 0 -2px black,
        1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
}

button[label=Accetto] {
    background-color: green;
}

button[label=Rifiuto] {
    background-color: red;
}