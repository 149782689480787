#TestoComunicazione {
    border: 2px solid black;
    border-radius: 5px;
}

#Contenuto #ComunicazioniAnonime,
#ComunicazioniAnonime form,
#ComunicazioniAnonime textarea {
    padding-top: 50px;
    width: 70vw;
    text-align: center;
}

#ComunicazioniAnonime button,
#ComunicazioniAnonime hr {
    width: 70vw;
}