footer {
    display: flex;
    align-items: center;
    bottom: 0;
    color: #666666;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 150px;
    min-width: 100%;
    width: 100%;
}

.left,
.center,
.right {
    float: left;
    min-width: 250px;
    width: 250px;
}

.left {
    text-align: right;
}

.center {
    text-align: center;
}

.center a {
    text-decoration: none;
}

.center a:hover {
    color: crimson;
}

.Fine:after {
    content: "";
    display: table;
    clear: both;
}

.Finale {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

footer span {
    padding-right: 15px;
}

@media screen and (max-width: 500px) {

    footer,
    .Finale {
        display: none;
    }
}