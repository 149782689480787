#Contenuto #ComunicazioniInterne {
    margin: 0 auto;
}

#TestoComunicazione {
    border: 2px solid black;
    border-radius: 5px;
}

#Contenuto #ComunicazioniInterne,
#ComunicazioniInterne form,
#ComunicazioniInterne textarea {
    padding-top: 50px;
    width: 70vw;
    text-align: center;
}

#ComunicazioniInterne button,
#ComunicazioniInterne hr {
    width: 70vw;
}