#Contenuto #ConsultazioneProtocolli {
    margin: 0 auto;
}

#Contenuto #ConsultazioneProtocolli,
#Contenuto #ConsultazioneProtocolli #Tabelle {
    text-align: center;
    padding-top: 80px !important;
    width: 70vw !important;
    min-width: 70vw !important;
}