#Contenuto #Programmazione {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    ;
}

#Contenuto #Programmazione * {
    width: 95%;
    min-width: 42vw;
}