#Contenuto #ControlloOrari {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    min-height: 30vh;
    align-items: flex-start !important;
    max-width: 60vw;
    padding-top: 50px;
    gap: 1vw !important;
}

#Contenuto #ControlloOrari #Tempi,
#ControlloOrari #Tempi select {
    min-width: 25vw;
    width: 25vw;
}

label[for='Firme'] {
    margin-bottom: 25px !important;
}

#Contenuto #ControlloOrari #Firme {
    border: 2px solid black;
    min-width: 25vw;
    max-width: 25vw;
    margin-left: 25px;
    margin-top: 35px;
}

#UtenzaScelta {
    font-size: 50px;
    font-weight: bolder;
    border: 2px solid goldenrod;
    border-radius: 10px;
}

#Contenuto #ControlloOrari #Tabelle {
    width: 70vw !important;
    min-width: 70vw !important;
}

#Contenuto #ControlloOrari p {
    color: blue !important;
}

#Contenuto #ControlloOrari p:hover {
    color: red !important;
}