#Contenuto #IngressiUscite {
    margin: 0 auto;
}

#IngressiUscite #Fine {
    background-color: crimson;
}

#Contenuto #IngressiUscite,
#Contenuto #IngressiUscite canvas {
    width: 50vw !important;
    min-width: 50vw !important;
    padding-top: 50px;
    text-align: center;
}

#IngressiUscite button {
    width: 50vw !important;
    min-width: 50vw !important;
}


#Contenuto #IngressiUscite canvas {
    height: 42vh !important;
}