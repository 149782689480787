#Contenuto #HomePage {
    margin: 0 auto;
    max-width: 52vw !important;
    min-width: 52vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Contenuto #HomePage a {
    text-decoration: none;
}

#HomePage #Logo {
    max-width: 52vw;
    padding-top: 50px;
}

#Contenuto #HomePage button {
    min-width: 52vw !important;
    max-width: 52vw !important;
}

#Contenuto #HomePage #Comunicazioni {
    background: orange;
    color: black;
}

#Contenuto #HomePage #Preventivi {
    background: paleturquoise;
    color: black;
}

#Contenuto #HomePage #Certificazioni {
    background: gold;
    color: black;
}