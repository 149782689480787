#Navigation {
    position: fixed;
    inset: 20px 0 20px 20px;
    width: 70px;
    background-image: transparent;
    border-right: 1px solid lightgrey;
    /* transition: 0.25s; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

#Navigation ul {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    margin-top: 100px;
    gap: 10px;
    width: 100%;
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

#Navigation ul {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

#Navigation ul::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

#Navigation ul li {
    list-style: none;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    /*  transition: 0.25s; */
}

#Navigation ul li.Active {
    transform: translateX(30px);
}

#Navigation ul li.Active {
    transform: translateX(10px);
}

#Navigation ul li a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    text-decoration: none;
}

#Navigation ul li a .Icon {
    position: reletive;
    display: block;
    min-width: 40px;
    height: 40px;
    line-height: 48px;
    transition: 0.25s;
    border-radius: 10px;
    font-size: 1.75em;
    text-decoration: none;
}

#Navigation ul li a .Icon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color);
    filter: blur(8px);
    opacity: 0;
    transition: 0.25s;
    text-decoration: none;
}

#Navigation ul li a .Text {
    position: relative;
    padding: 0 15px;
    height: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: grey;
    opacity: 0;
    visibility: hidden;
    text-decoration: none;
    /* transition: 0s; */
}

#Navigation.Open ul li a .Text {
    opacity: 1;
    visibility: visible;
    height: 40px;
    line-height: 48px;
    text-decoration: none;
}

#Navigation.Open ul li.Active a .Text {
    color: var(--color);
    height: 40px;
    line-height: 48px;
    text-decoration: none;
}

#Navigation ul li.Active a .Icon {
    color: whitesmoke;
    background: var(--color);
    text-decoration: none;
}

#Navigation.Open {
    width: 210px;
}

#Navigation #MenuToggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 60px;
    border-bottom: 1px solid black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 17px;
}

#Navigation #MenuToggle::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: grey;
    transform: translateY(-8px);
    transition: 0.25s;
}

#Navigation.Open #MenuToggle::before {
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 0 0 transparent;
}

#Navigation #MenuToggle::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: grey;
    transform: translateY(8px);
    transition: 0.25s;
    box-shadow: 0 -8px 0 grey;
}

#Navigation.Open #MenuToggle::after {
    transform: translateY(0px) rotate(-45deg);
    box-shadow: 0 0 0 transparent;
}

a.List:visited {
    color: initial;
}

a.List {
    text-decoration: none;
}