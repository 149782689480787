#Contenuto #Quest {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 50vw;
    min-width: 50vw;
    padding-top: 100px;
}

#Quest form {
    display: flex;
    flex-direction: column;
    text-align: center !important;
    width: 50vw;
    max-width: 50vw;
}

#Quest textarea,
#Quest button {
    width: 50vw;
    max-width: 50vw;
}

#Quest h3 {
    margin-bottom: 15px;
}

#Quest p {
    font-weight: 400;
    font-style: italic;
}