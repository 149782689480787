#Contenuto #Protocolli {
    margin: 0 auto;
    padding-top: 50px;
    width: 70vw;
    min-width: 70vw;
    text-align: center;
}

#Protocolli button {
    width: 70vw;
}