#Contenuto #Scansioni {
    margin: 0 auto;
    padding-top: 10vh;
    padding-bottom: 42vh;
    min-width: 70vw;
    width: 70vw !important;
}

#Scansioni button {
    min-width: 70vw;
    width: 70vw !important;
}

#nfc {
    background-color: green;
    margin-top: 30px !important;
}