#Contenuto #Ordini {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 8vw;
    width: 70vw;
    min-width: 70vw;
    padding-top: 50px !important;
}

#Contenuto #Cantiere {
    padding-top: 50px !important;
}

#Cantiere div {
    margin-top: 25px;
}

#Contenuto #Ordini #Cantiere,
#Contenuto #Ordini #Cantiere #Cantieri,
#Contenuto #Ordini #Tabelle {
    text-align: center;
    min-width: 70vw;
    width: 70vw;
}

#Contenuto #Ordini #Cantiere #Cantieri {
    background-color: aqua;
}

#Cantiere .Selezione {
    display: flex;
    gap: 8vw;
    min-width: 50vw;
    width: 50vw;
}

#Cantiere div:not(.Selezione) {
    display: flex;
    gap: 2vw;
}

#Contenuto #Ordini #Ordina {
    width: 100%;
}