#Contenuto #Rapporti {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5vw;
    width: 70vw;
    min-width: 70vw;
    text-align: center;
    padding-top: 50px;
}

#Contenuto #Rapporti {
    padding-top: 50px !important;
}

#SubmitRapporto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-column-gap: 3vw;
    -moz-column-gap: 3vw;
    column-gap: 3vw;
    width: 60vw;
    min-width: 27vw;
    max-width: 60vw;
    padding-top: 25px;
    vertical-align: middle !important;
}

.Centrato {
    width: 60vw;
    min-width: 27vw;
    padding-top: 5px;
    flex-grow: 1;
    flex-shrink: 1;
}

textarea {
    resize: none;
}

/* 
textarea::-webkit-input-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

textarea::-moz-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

textarea:-ms-input-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

textarea::-ms-input-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

textarea::placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
 */

input:not(#Incaricati *, #Commessa *) {
    height: 72px;
    max-height: 72px;
}

#Incaricati {
    border: 2px solid black;
}

.css-1dimb5e-singleValue,
.css-3w2yfm-ValueContainer {
    font-weight: bolder;
    font-size: x-large;
}

.ComeLabel {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    font-weight: bolder;
}

canvas {
    width: 100% !important;
    min-width: 100% !important;
    height: 35vh;
}

@media only screen and (max-width: 768px) {

    /* NON TESTATO ANCORA */
    input[type='checkbox'] {
        align-items: center;
        justify-content: center;
    }
}