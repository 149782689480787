#Contenuto #StatoPulizie {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    gap: 8vw;
    width: 50vw;
    min-width: 50vw;
    padding-top: 50px;
}

#Contenuto #StatoPulizie #Tempi {
    text-align: center;
    align-items: center;
    width: 42vw;
    margin: 0 auto;
}

#Contenuto #StatoPulizie #Tabelle {
    min-width: 70vw;
    margin: 0 auto;
}