#Contenuto #PREVENTIVI,
#PREVENTIVI form {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 70vw;
    min-width: 70vw;
    padding-top: 50px;
}

#PREVENTIVI select,
#PREVENTIVI button,
#PREVENTIVI textarea {
    width: 100%;
    margin-bottom: 25px;
}

#Contenuto #PREVENTIVI #Calcola,
#Contenuto #PREVENTIVI #Pavimento,
#Contenuto #PREVENTIVI #Pavimento2,
#Contenuto #PREVENTIVI #Bagni,
#Contenuto #PREVENTIVI #Frequenza {
    display: none;
    width: 70vw;
    min-width: 70vw;
}