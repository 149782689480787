#Contenuto #Piani {
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
    width: 70vw;
    min-width: 70vw;
    text-align: center;
    padding-top: 50px;
}

#PianiDiLavoro {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    column-gap: 1vw;
    row-gap: 1vh;
    max-width: 70vw;
    padding-top: 25px;
}

#PianiDiLavoro>div,
#PianiDiLavoro input {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1vw;
    height: 4vh;
    min-height: 4vh;
    max-height: 4vh;
}

#PianiDiLavoro textarea {
    padding-top: 10px;
    border: 1px solid black;
    height: 19vh;
    min-height: 19vh;
    max-height: 19vh;
}

#PianiDiLavoro textarea[name='Nome']:focus {
    outline: none;
    border: 1px solid transparent;
}

.LargoCosi {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 11vw;
}

.SelectWidth {
    min-width: 200px;
    max-width: 11vw;
    width: 11vw;
}

.OreWidth {
    min-width: 100px;
    max-width: 7vw;
    width: 7vw;
}

.NoteWidth>span {
    max-width: 200px;
    width: 15vw;
}

.Note {
    min-height: 170px !important;
}

.css-b62m3t-container div:not(.css-1p3m7a8-multiValue) {
    padding-inline: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.css-1p3m7a8-multiValue div {
    font-size: 80%;
    padding: 0;
}