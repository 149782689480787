#Contenuto #Cert {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	width: 70vw;
	min-width: 70vw;
	padding-top: 50px;
	font-weight: bolder;
	font-size: 30px;
	line-height: 300%;
	margin: 0 auto;
}

#Cert div {
	width: 30vw;
	max-width: 30vw;
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0 auto;
}
#Cert button {
	text-align: center;
	min-width: 300px;
	width: 300px;
	margin: 0 auto;
}

#Cert .right {
	text-align: center;
}

#Displayer,
#Displayer *,
#Noter {
	width: 70vw !important;
	max-width: 70vw !important;
	min-height: 75vh;
}
