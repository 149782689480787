#Contenuto #SMS {
    margin: 0 auto;
    padding-top: 50px;
    text-align: center;
}

#Contenuto #SMS button,
#Contenuto #SMS select,
#Contenuto #SMS textarea,
#Contenuto #SMS {
    width: 50vw !important;
    min-width: 50vw;
    margin-bottom: 30px;
}


#Contenuto #SMS #InviaATutti {
    height: 70px !important;
    background-color: black;
}

#Contenuto #SMS #InviaSMS {
    height: 100px !important;
    background-color: red;
}