* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
}

body {
    background-image: linear-gradient(to top, rgba(94, 255, 0, 0.25), white 25%);
    background-attachment: fixed !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button,
input[type="button"],
input[type="submit"] {
    background-color: #33a02c;
    border: 1px solid black;
    border-radius: 8px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    height: 50px !important;
    font-weight: bolder;
    margin-top: 10px !important;
}

input,
button,
select,
textarea,
label {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    display: block;
    width: 18vw;
    min-width: 300px;
}

label {
    margin: 0 auto !important;
    margin-top: 10px !important;
}

input {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.data-table-extensions-filter {
    width: 18vw;
    min-width: auto;
}

textarea {
    border: 2px solid black;
    border-radius: 15px;
    text-align: center;
}

input {
    border-radius: 5px;
    height: 50px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 25px;
}

select {
    width: 18vw;
    min-width: 300px;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    font-weight: bold;
    font-size: 25px;
    height: 50px !important;
}

h1 {
    position: absolute;
    top: 20px;
}

label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -10px;
    margin-top: 10px;
}

hr {
    border: 2px solid grey;
    border-radius: 50%;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}

section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    flex: 1;
    text-align: end;
    justify-items: end;
    width: 80vw;
}

canvas {
    border: 2px solid black;
    border-radius: 10px;
}

form {
    display: flex;
    flex-wrap: wrap;
}

.nodisplay {
    /* display: none; */
    visibility: hidden;
}

.display {
    /* display: block; */
    visibility: visible;
}

input[type="file"] {
    display: none;
}

.InputFile {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: black;
    color: white;
    cursor: pointer;
    height: 45px;
    padding: 10px;
    margin-bottom: 15px;
}

.CaricaDoc {
    display: inline-block;
    border: 2px solid green;
    border-radius: 10px;
    background-color: lightseagreen;
    color: whitesmoke;
    cursor: pointer;
    height: 45px;
    padding: 10px;
    margin-bottom: 10px;
}

.CookieConsent {
    width: 100% !important;
    padding-right: 2vw !important;
}

.CookieConsent div {
    width: 25% !important;
    display: inline !important;
}

#Tabelle {
    width: 83vw !important;
    max-width: 83vw !important;
}

#Tabelle div,
#Tabelle div nav {
    background: transparent;
}

.data-table-extensions,
.data-table-extensions-filter,
.data-table-extensions-filter .icon,
.data-table-extensions-action,
.data-table-extensions-action .download {
    margin: 0 !important;
    padding: 0 !important;
    height: 60px;
}

#Contenuto {
    transition: 0.25s;
    position: relative;
    left: 130px;
    max-width: 80vw;
}

#Contenuto.Open {
    transition: 0.25s;
    position: relative;
    left: 300px;
    max-width: 80vw;
}

#Tabelle select {
    width: 50px !important;
    max-width: 50px !important;
}

#Tabelle nav {
    display: flex;
    flex-wrap: wrap;
    width: 55vw !important;
    max-width: 55vw !important;
    position: relative;
    left: 300px;
}

#pagination-first-page,
#pagination-previous-page,
#pagination-next-page,
#pagination-last-page {
    border: 2px solid gray;
    border-radius: 5px;
}

.dLyRAV {
    min-width: 50px !important;
}

.filter-text {
    background: lightslategray !important;
    border: 2px solid black !important;
    border-radius: 10px !important;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--warning {
    border: 2px solid black;
    border-radius: 15px !important;
}

.Toastify__close-button {
    display: none !important;
}

[role=columnheader] {
    background-color: blue !important;
    color: whitesmoke;
    font-size: medium;
    font-weight: bolder;
    border-bottom: 1px solid black;
    border-top: 1px solid black;

    border-top: 10%;
}

[role=row] {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.toastMessage.forceActionsText {
    white-space: pre-line !important;
}

#NonSuCellulare {
    display: none;
}

.sc-ezOQGI button {
    min-width: 165px !important;
}

.sc-iJnaPW {
    margin: 0 0 100px -205px !important;
}

.ComeLabel {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    font-weight: bolder;
}

/* Media query */

@media only screen and (max-width: 768px) {
    body {
        max-width: 50vw !important;
    }

    #Contenuto>div {
        min-width: 50vw !important;
        width: 50vw !important;
        max-width: 50vw !important;
    }

    #Contenuto.Open {
        left: 500px !important;
    }

    #Contenuto {
        left: 115px;
    }

    footer,
    #Contenuto #Anagrafiche {
        display: none !important;
    }

    h1 {
        font-size: x-large;
    }

    h3 {
        font-size: large;
    }

    #Logo {
        padding-top: 75px !important;
    }

    form,
    button,
    input,
    label,
    textarea,
    select,
    hr,
    canvas,
    sub,
    #Disclamer,
    #MostraFile,
    #Caricamenti {
        width: 200px !important;
        /* 220 */
        min-width: 200px !important;
        /* 220 */
        max-width: 200px !important;
        /* 220 */
        font-size: medium !important;
        margin: 5px auto 5px -5px !important;
    }

    #Caricamenti {
        background: transparent !important;
        border: none !important;
    }

    #MostraFile {
        height: fit-content !important;
    }

    hr {
        margin-top: 50px !important;
    }

    .right {
        text-align: left !important;
        margin-left: 15px;
    }

    #ScaricaKit {
        margin-left: -10px !important;
    }

    #Contenuto #StatoPulizie #Tempi {
        width: 50vw !important;
    }

    #Contenuto #Prodotti {
        display: grid !important;
    }

    #Contenuto #ControlloOrari #Firme,
    #Contenuto #ControlloOrari #UtenzaScelta {
        font-size: 35px !important;
        min-width: 220px !important;
        max-width: 220px !important;
        margin-left: -5px !important;
        margin-top: 5px !important;
    }

    #Contenuto #ControlloOrari #Tempi {
        width: 50vw !important;
    }

    #Contenuto #Ordini #Cantiere #Cantieri,
    #Contenuto #Ordini button {
        max-width: 180px !important;
    }

    #Cantiere .Selezione {
        min-width: 180px !important;
        width: 180px !important;
        gap: 0vw !important;
    }

    #Cantiere .Quantitativi {
        gap: 0vw !important;
        min-width: 40px !important;
        max-width: 40px !important;
        width: 40px !important;
    }

    #NonSuCellulare {
        display: block !important;
        text-align: center;
        margin: 100% 0 0 -10% !important;
        width: 70vw;
        font-size: xx-large;
        font-weight: bolder;
    }

    .sc-ezOQGI button,
    .sc-cwSeag {
        width: 48px !important;
        min-width: 48px !important;
        max-width: 48px !important;
    }

    .sc-iJnaPW {
        margin: 0 0 100px -265px !important;
    }


    /* #Turni #Input *,
    #Turni #Input #ModificaTurno * {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 65vw !important;
        min-width: 65vw !important;
        max-width: 65vw !important;
        padding: 0px;
    } */

    #SpecificheCellulare {
        max-width: 55vw;
    }
}