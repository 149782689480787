#Contenuto #Turni {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    width: 90vw;
    min-width: 90vw;
    text-align: center;
    padding-top: 50px;
}

#Contenuto #Turni {
    padding-top: 50px !important;
}

#Contenuto.Open:has(#Turni) #Input,
#Contenuto.Open #Turni #Input,
#Contenuto #Turni #Input,
#Turni #Input button {
    min-width: 18vw !important;
    width: 18vw !important;
}

#Contenuto #Turni #Tabelle {
    width: 70vw;
    max-width: 70vw !important;
}

#Contenuto #Turni #Tabelle nav {
    width: 35vw !important;
    max-width: 35vw !important;
}

#TurniSettimanali {
    font-size: 105%;
}

/* Nuove */

#ModificaTurno div input {
    height: 40px;
    max-height: 40px;
    min-height: 40px;
}

#Contenuto #Turni #Tabelle {
    width: 200% !important;
    min-width: 70vw !important;
    max-width: 200% !important;
}

#Turni #Input>div,
#Turni #Input #Pulsantiera,
#Turni #Input p,
#Turni #Input button,
#Turni #Input label,
#Turni #Input sub,
#Turni #Input textarea {
    min-width: 200px !important;
    margin-top: 15px;
}