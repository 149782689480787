#Contenuto #Prodotti {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10vw;
    width: 70vw;
    min-width: 70vw;
    text-align: center;
    padding-top: 50px;
}

#Prodotti #NewProduct,
#Prodotti #ModificaProdotto,
#ModificaProdotto select,
#ModificaProdotto button,
#NewProduct select,
#NewProduct button {
    width: 30vw !important;
    min-width: 30vw !important;
    max-width: 30vw !important;
}

#ModificaProdotto select,
#NewProduct button,
#ModificaProdotto button {
    min-width: 220px !important;
}

#Contenuto #Prodotti #ModificaMi {
    background: orange;
}