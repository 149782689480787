#UploadIMG {
    min-height: 400px !important;
}

#UploadIMG img {
    display: inline;
    max-height: 250px;
    max-width: 300px;
}

#Anagrafiche #Pulsantiera {
    margin-left: 12rem;
}

#CreaDip,
#AggiornaDip {
    width: 80vw !important;
    max-width: 80vw !important;
    margin-bottom: 50px;
}

#FormModificaUtente {
    margin-top: 50px;
}

#Anagrafiche select {
    height: 72px !important;
    max-height: 72px !important;
}

#Anagrafiche textarea {
    width: 80vw !important;
    max-width: 80vw !important;
    margin-bottom: 25px;
}

#NoteCreazione,
#NoteCreazione label {
    display: inline;
}

#Anagrafiche {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    flex: 1;
    text-align: center;
}

#CreaDipendenti,
#ModificaDipendenti {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    flex: 1;
    text-align: center;
}

#FileShow {
    height: 85%;
    max-width: 58vw;
    width: 242% !important;
    border: 2px solid lightgrey;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.Colonna {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
}

.CantiereAttuale {
    font-weight: bold;
    display: flex;
    margin-left: 125px;
    margin-top: 15px;
    font-size: larger;
    color: indianred;
}

.Orange {
    background-color: darkorange !important;
}

#loading {
    display: block;
    z-index: 1000 !important;
    min-width: 30vw;
    position: fixed;
    top: 10px;
    left: 25vw;
}

.filter-text {
    color: white !important;
}


#Documentazione div {
    background-color: whitesmoke;
    border: 1px dashed navy;
    border-radius: 4%;
    margin-bottom: 25px;
}