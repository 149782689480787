#Contenuto #Customer {
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	min-height: 100vh;
	width: 70vw;
	min-width: 70vw;
	padding-top: 75px;
	row-gap: 1vw !important;
	column-gap: 3vh !important;
}

#Customer #BOX,
#Customer #BoxReport {
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 25px;
	column-gap: 3vw !important;
}
#Customer #Scelte,
#Customer #SceltaReport {
	display: flex;
	text-align: center;
	flex-direction: column;
	min-height: 30vh;
	max-width: 20vw;
	row-gap: 1vw;
}

#Customer #Domande,
#Customer #Report {
	display: flex;
	text-align: center;
	align-items: center !important;
	justify-content: center;
	flex-direction: column;
	row-gap: 15px;
	padding: 25px;
	min-width: 45vw;
	max-width: 45vw;
	width: 45vw;
	height: fit-content;
	min-height: fit-content;
	transition: opacity 0.3s ease, height 0.3s ease;
}

#Domande span,
#Report p {
	display: flex;
	flex-direction: column;
	align-items: center;
}
#Voti {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	width: 100%;
	justify-content: center;
	text-align: center;
	align-items: center;
	transition: opacity 0.3s ease, height 0.3s ease;
}

#Edificio,
#Service,
#Domande,
#Domanda,
#Voti {
	display: none;
}

#Smile * {
	width: 300px;
	height: 300px;
}
